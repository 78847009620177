






































import clone from 'clone';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';
import { apiGETMulti } from '@/api';

@Component
export default class extends Vue {
  @Prop([String, Array]) readonly value!: string | string[];
  @Prop({ type: String, default: 'Main Channel' }) readonly label!: string;
  authorisedChannels: API.AuthorisedChannels.Channels.GET[] = [];
  loading = false;

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    this.authorisedChannels = (await apiGETMulti('authorisedChannels', {}, true)).data;
    this.loading = false;
  }

  async created(): Promise<void> {
    await this.loadAPIData();
  }

  get allAuthorisedChannels(): { name: string, id: string }[] {
    const unknown = (Array.isArray(this.value) ? this.value : [this.value])
      .filter((id) => id && !this.authorisedChannels.find((c) => c.id === id));
    return unknown
      .map((id) => ({ name: `unknown (${id})`, id }))
      .concat(this.authorisedChannels.map(({ name, id }) => ({ name, id })));
  }

  filter(chan: API.AuthorisedChannels.Channels.GET, queryText: string): boolean {
    const q = queryText.toLowerCase();
    return chan.name.toLowerCase().includes(q);
  }

  get multiple(): boolean {
    return Array.isArray(this.value);
  }

  removeMultiple(chan: API.AuthorisedChannels.Channels.GET): void {
    if (Array.isArray(this.value)) {
      const index = this.value.findIndex((id) => id === chan.id);
      if (index >= 0) {
        const arr = clone(this.value);
        arr.splice(index, 1);
        this.$emit('input', arr);
      }
    }
  }

  input($event?: string | string[]): void {
    if (Array.isArray($event)) {
      this.$emit('input', $event);
    } else {
      this.$emit('input', $event ?? null);
    }
  }
}
