

















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component
export default class extends Vue {
  @Prop(String) readonly value!: string | null | undefined;
  @Prop(String) readonly minTimestamp!: string | null | undefined;
  @Prop(String) readonly maxTimestamp!: string | null | undefined;
  @Prop(String) readonly prefill!: string | null | undefined;
  @Prop({ type: String, required: true }) readonly label!: string;
  @Prop(String) readonly icon!: string | undefined;
  @Prop(Boolean) readonly margin!: boolean;
  date = dayjs().format('YYYY-MM-DD');
  time: string | null = null;
  dialog = false;

  get minDate(): string | undefined {
    return this.minTimestamp ? dayjs(this.minTimestamp).format('YYYY-MM-DD') : undefined;
  }

  get minTime(): string | undefined {
    if (this.minDate !== this.date) return undefined;
    return this.minTimestamp ? dayjs(this.minTimestamp).format('HH:mm') : undefined;
  }

  get maxDate(): string | undefined {
    return this.maxTimestamp ? dayjs(this.maxTimestamp).format('YYYY-MM-DD') : undefined;
  }

  get maxTime(): string | undefined {
    if (this.maxDate !== this.date) return undefined;
    return this.maxTimestamp ? dayjs(this.maxTimestamp).format('HH:mm') : undefined;
  }

  get textFieldValue(): string | null {
    return this.value
      ? `${this.date} ${this.time} `
        + `| ${dayjs(`${this.date} ${this.time}`).tz('Europe/Stockholm').format('HH:mm')} (Sweden)`
      : null;
  }

  @Watch('value', { immediate: true })
  onValueChange(val: string | null): void {
    const date = val && dayjs(val);
    if (date && date.isValid()) {
      this.date = date.format('YYYY-MM-DD');
      this.time = date.format('HH:mm');
    } else {
      this.date = dayjs().format('YYYY-MM-DD');
      this.time = null;
    }
  }

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (val) {
      // Use prefill date if we currently have no value and it exists.
      const date = !this.value && this.prefill && dayjs(this.prefill);
      if (date && date.isValid()) {
        this.date = date.format('YYYY-MM-DD');
        this.time = date.format('HH:mm');
      }
    }
  }

  save(): void {
    if (this.time) {
      // Only save timestamp if it differs from the prefill.
      const prefillDate = this.prefill && dayjs(this.prefill).toISOString();
      const newDate = dayjs(`${this.date} ${this.time}`).toISOString();
      if (!prefillDate || newDate !== prefillDate) {
        this.$emit('input', dayjs(`${this.date} ${this.time}`).toISOString());
      }
    }
  }
}
