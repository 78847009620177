


























































































































































































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { API } from '@/types';
import ModifyDialog from '@/components/Admin/ModifyDialog.vue';
import TextInput from '@/components/Admin/TextInput.vue';
import ImageSelect from '@/components/Admin/ImageSelect.vue';
import SwitchInput from '@/components/Admin/SwitchInput.vue';
import ChannelInput from '@/components/Admin/ChannelInput.vue';
import Tooltip from '@/components/Admin/Tooltip.vue';
import { formatCurrencyStr, formatCurrencyVal, removeDBTimestamps } from '@/util';
import DateTimeInput from './Modify/DateTimeInput.vue';
import { apiModify } from '@/api';
import clone from 'clone';
import SponsorTable from '@/components/Admin/SponsorTable.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

const defaultEventObject: API.Events.POST = {
  timingMethod: 'RealTime',
  channelIds: [],
  dayTimestamps: [{ start: '', end: '' }],
  prizes: [],
  prizeCurrency: 'USD',
  sponsorIds: [],
  public: false,
  archived: false,
};

@Component({
  components: {
    ModifyDialog,
    TextInput,
    ImageSelect,
    SwitchInput,
    ChannelInput,
    DateTimeInput,
    Tooltip,
    SponsorTable,
  },
})
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @Prop(Object) readonly modifyItem!: API.Events.GET | undefined;
  event = clone(defaultEventObject);
  eventId: number | null = null;
  err: boolean | string = false;
  tab = null;
  formatCurrencyVal = formatCurrencyVal;
  formatCurrencyStr = formatCurrencyStr;

  @Watch('dialogProp')
  onDialogChange(val: boolean): void {
    if (val) {
      this.tab = null;
      if (this.modifyItem) {
        this.event = removeDBTimestamps(this.modifyItem) as API.Events.POST;
        this.eventId = this.modifyItem.id;
        if (!this.event.dayTimestamps?.length) {
          this.event.dayTimestamps = [{ start: '', end: '' }];
        }
      }
    }
  }

  addDay(): void {
    if (!this.event.dayTimestamps) this.event.dayTimestamps = [{ start: '', end: '' }];
    this.event.dayTimestamps.push({ start: '', end: '' });
  }

  removeDay(index: number): void {
    if (this.event.dayTimestamps && this.event.dayTimestamps.length > 1) {
      this.event.dayTimestamps.splice(index, 1);
    }
  }

  updateDayTimestamp(index: number, key: 'start' | 'end', value: string): void {
    if (typeof this.event.dayTimestamps === 'undefined') {
      this.event.dayTimestamps = [{ start: '', end: '' }];
    }
    const day = this.event.dayTimestamps[index];
    if (!day && key === 'start') this.event.dayTimestamps.push({ start: value, end: '' });
    if (!day && key === 'end') this.event.dayTimestamps.push({ start: '', end: value });
    else {
      Vue.set(this.event.dayTimestamps, index, {
        ...this.event.dayTimestamps[index],
        [key]: value,
      });
    }
  }

  addPrize(): void {
    if (this.event.prizes) this.event.prizes.push(0);
  }

  deletePrize(index: number): void {
    if (this.event.prizes) this.event.prizes.splice(index, 1);
  }

  prizeEdited(index: number, val: string): void {
    if (typeof this.event.prizes?.[index] !== 'undefined') {
      Vue.set(this.event.prizes, index, Number(val) * 100);
    }
  }

  cancel(): void {
    this.event = clone(defaultEventObject);
    this.eventId = null;
    this.err = false;
  }

  async save(): Promise<void> {
    try {
      const { data } = await apiModify('events', this.eventId, this.event);
      this.event = clone(defaultEventObject);
      this.eventId = null;
      this.err = false;
      this.dialogProp = false;
      this.$emit('post', data);
    } catch (err) {
      const { data } = err.response;
      if (data && data.error && !Array.isArray(data.error)) {
        this.err = data.error;
      } else {
        this.err = 'Some server error occured';
      }
    }
  }
}
