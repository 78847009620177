


































import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import DataTable from '@/components/Admin/DataTable.vue';
import Modify from './Modify.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  events: API.Events.GET[] = []; // Only stores the current page of events!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyEvent: API.Events.GET | null = null; // Event to be modified/deleted.
  options = {
    apiName: 'events',
    socketName: 'eventModified',
    idStr: 'id',
    embeds: [
      { name: 'flyer', socketName: 'imageModified', idStr: 'flyerId' },
      { name: 'thumbnail', socketName: 'imageModified', idStr: 'thumbnailId' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Start', value: 'startingTimestamp' },
      { text: 'End', value: 'endingTimestamp' },
      { text: 'Created', value: 'creationTimestamp' },
    ],
    modifyComponent: Modify,
  };
  dayjs = dayjs;
}
